<template>
  <div class="detail">

    <div class="content">
      <div class="head">
        <div class="head-button">
          <el-form ref="form">
            <el-form-item label="本地目录" class="platform-choose">
              <el-button type="primary" size="mini" @click="openDirSelect">选择</el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-button v-show="isSelectMode" type="primary" size="mini" style="height: 30px" @click="selectDone">确定选择</el-button>
          </div>
        </div>

        <div class="select-dir">
          <div v-for="selectDir in dirList">
            <div class="select-dir-one" :class="{active:activeDir===selectDir}" @click="changeDir(selectDir)">
              <i class="el-icon-folder"></i>
              <span>&nbsp&nbsp{{ selectDir }}</span>
              <i class="el-icon-close" @click.stop="delDir(selectDir)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="select-box" v-show="selectImgList.length > 0 && isSelectMode">
        <div class="img-one-select" style="border: 0;">已选择: </div>
        <div v-for="img in selectImgList" class="img-one-select">
          <img  v-if="isImage(img)" :src="img" alt="" class="img">
          <video v-if="isVideo(img)" width="320" controls height="240" class="img">
            <source :src="img" type="video/mp4">
          </video>
        </div>
      </div>

      <div>
        <div v-for="img in imageList" class="img-one">
          <div v-if="isImage(img)">
            <img :src="img" alt="" @click="clickImg(img)" class="img">
            <span class="img-url">{{ getShowImgUrl(img) }}</span>
          </div>
          <div v-if="isVideo(img)">
            <video width="320" height="240" @click="clickImg(img)" class="video">
              <source :src="img" type="video/mp4">
            </video>
            <span class="img-url">{{ getShowImgUrl(img) }}</span>
          </div>

        </div>
      </div>

    </div>
    <!-- 图片预览对话框 -->
    <el-dialog
      :z-index="2500"
      :visible.sync="imgPreviewVisible"
      :fullscreen="true">
      <img :src="currentImg" alt="Image Preview" style="width: 100%;" @click="previewVisible=false">
    </el-dialog>
    <el-dialog
      :z-index="2500"
      :visible.sync="videoPreviewVisible"
      :fullscreen="true">
      <div class="video-preview">
        <el-button @click="videoPreviewVisible=false" style="margin-bottom:10px;">关闭</el-button>
        <video width="320" height="240" controls class="video">
          <source :src="currentImg" type="video/mp4">
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {isImage, isPcClient, isVideo} from "@/utils/utils";

export default {
  //isSelectMode是传入参数
  props: {
    isSelectMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localPath: '',
      dirList: [],
      currentDir: '',
      imageList: [],

      selectImgList: [],

      // 控制图片预览对话框的显示
      imgPreviewVisible: false,
      videoPreviewVisible: false,
      // 当前要显示的图片
      currentImg: '',
      activeDir: '',
    };
  },

  mounted() {
    //如果存在query的id， 则加载数据
    this.init()
    console.log('选择模式', this.isSelectMode)
  },
  watch: {
    dirList(to, from) {
      console.log(from, to)
      //数据保存到服务器
      let localDir = this.dirList?.length > 0 ? this.dirList.join(',') : ''
      this.$http.post('/client/user/updateLocalDir', {localDir: localDir}).then(res => {
        console.log('本地目录已更新')
      })
    }
  },
  methods: {
    init() {
      this.$http.post('/client/user/info', {}).then(res => {
        this.dirList = res?.data?.userInfo?.localDir
        if (this.dirList?.length > 0) {
          this.changeDir(this.dirList[0])
        }
      })
    },
    changeDir(dir) {
      //打开目录选择
      if (!isPcClient()) {
        this.$message.error('请在PC端使用')
        return
      }
      pywebview.api.searchFileInPath(dir).then(res => {
        //如果res[0]已经存在，则不添加
        if (this.dirList.indexOf(res[0]) === -1) {
          this.dirList.push(res[0])
        }
        this.activeDir = res[0]
        this.imageList = res[1]
      })
    },
    delDir(dir) {
      this.dirList = this.dirList.filter(item => item !== dir)
      if (this.dirList.length > 0) {
        this.activeDir = this.dirList[0]
        pywebview.api.searchFileInPath(this.activeDir).then(res => {
          this.imageList = res[1]
        })
      } else {
        this.activeDir = null
        this.imageList = []
      }
    },
    openDirSelect() {
      //打开目录选择
      if (!isPcClient()) {
        this.$message.error('请在PC端使用')
        return
      }
      this.activeDir = null
      pywebview.api.searchFileInPath(this.activeDir).then(res => {
        if (res[0].length === 0) {
          return
        }
        if (this.dirList.indexOf(res[0]) === -1) {
          this.dirList.push(res[0])
        }
        this.activeDir = res[0]

        this.imageList = res[1]
      })
    },

    getShowImgUrl(img) {
      // 去掉http和端口部分
      img = img.replace('http://localhost:', '')
      // 去掉开头的数字
      img = img.replace(/^\d+/, '')
      // 去掉开头的/
      img = img.replace(/^\//, '')
      // 如果小于20个字，直接返回, 否则返回前10个字和后10个字
      if (img.length < 20) {
        return img
      } else {
        return img.substr(0, 6) + '...' + img.substr(-14)
      }
    },

    clickImg(img) {
      if (this.isSelectMode) {
        //如果存在 则删除，否则添加
        let index = this.selectImgList.indexOf(img)
        if (index !== -1) {
          this.selectImgList.splice(index, 1)
        } else {
          let maxCount = 30
          if (this.selectImgList.length >= maxCount) {
            this.$message.error(`最多选择${maxCount}张图片`)
            return
          }
          if (this.isVideo(img)) {
            this.selectImgList.length > 0 && this.$message.success('视频只能选择一个')
            this.selectImgList = []
          }
          if (this.isImage(img)) {
            if (this.isVideo(this.selectImgList[0])) {
              this.selectImgList.length > 0 && this.$message.success('视频和图片不能同时选择')
              this.selectImgList = []
            }
          }
          this.selectImgList.push(img)
        }
      } else {
        if (this.isImage(img)) {
          this.showImgDetail(img)
        } else if (this.isVideo(img)) {
          this.showVideoDetail(img)
        }
      }
    },

    showImgDetail(img) {
      this.currentImg = img;
      this.imgPreviewVisible = !this.imgPreviewVisible;
    },
    showVideoDetail(img) {
      this.currentImg = img;
      this.videoPreviewVisible = !this.videoPreviewVisible;
    },

    selectDone() {
      this.$emit('selectDone', this.selectImgList)
    },
    isImage(str) {
      return isImage(str)
    },
    isVideo(str) {
      return isVideo(str)
    }
  },
};
</script>
<style lang="scss" scoped>
.detail {
  padding: 2rem;
  border: solid 1px #ebeef5;
  border-radius: 10px;
  margin: 1.5rem 0 0 1rem;
  .content {
    width: 100%;
    min-height: 400px;
    margin:0 auto;
    .platform-choose {
      display: flex;
      justify-content: flex-start;
      margin-top: 0;
    }

    .bind-sure {
      display: flex;
      justify-content: center;
    }

    ::v-deep .el-input--mini .el-input__inner {
      height: 28px;
      line-height: 28px;
      text-align: center;
    }
  }
}
h1 {
  text-align: center;
}

.img-one {
  width: 100px;
  height: 145px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  white-space: normal;
  .img {
    width: 100px;
    height: 100px;
  }
  .video {
    width: 100px;
    height: 100px;
  }
  .img-url {
    font-size: 11px;
    max-width: 100%;
    display: block;
    text-align: center;
    white-space: normal;
    padding: 0 5px;
    word-break: break-all;
  }
}
.select-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px #ebeef5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  .img-one-select {
    width: 50px;
    height: 50px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    .img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }
  }
}

.head {
  border-bottom: solid 1px #ebeef5;
  padding-bottom: 25px;
  margin-bottom: 10px;
  .head-button {
    display: flex;
    justify-content: space-between;
  }
  .select-dir {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .select-dir-one {
      margin: 5px;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      font-size: 12px;
      display: inline-block;
      .el-icon-close {
        font-size: 12px;
        cursor: pointer;
        margin-left: 12px;
        font-weight: 900;
      }
      .el-icon-close:hover {
        color: red;
        transform: scale(1.2);
        transition: all 0.5s;
      }
    }
    .active {
      border: 1px solid #409eff;
    }
  }
}
.video-preview {
  width: 100%;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
